import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./quotation.css";

class Quotation extends Component {
  showStatusTimer = 3; // show quotation sending status and auto close it in seconds

  initState = {
    name: "",
    phone: "",
    address: "",
    //postalcode: "",
    email: "",
    message: "",
    files: [],
    loading: false,
    status: 0,
  };

  constructor(props) {
    super(props);
    this.state = this.initState;
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    //this.handlePostalcodeChange = this.handlePostalcodeChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleAttachmentChange = this.handleAttachmentChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNameChange(event) {
    this.setState({
      name: event.target.value,
    });
  }

  handlePhoneChange(event) {
    this.setState({
      phone: event.target.value,
    });
  }

  handleAddressChange(event) {
    this.setState({
      address: event.target.value,
    });
  }

  // handlePostalcodeChange(event) {
  //   this.setState({
  //     postalcode: event.target.value,
  //   });
  // }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value,
    });
  }

  handleMessageChange(event) {
    this.setState({
      message: event.target.value,
    });
  }

  handleAttachmentChange(event) {
    this.setState({
      files: event.target.files,
    });
  }

  handleReset(event) {
    this.setState(this.initState);
    document.getElementById("attachmentFiles").value = "";
  }

  async handleSubmit(event) {
    event.stopPropagation();
    event.preventDefault();

    this.setState({
      loading: true,
    });

    //console.log("A message was submitted: " + JSON.stringify(this.state));

    var data = new FormData();
    data.append("name", this.state.name);
    data.append("phone", this.state.phone);
    data.append("address", this.state.address);
    //data.append("postalcode", this.state.postalcode);
    data.append("email", this.state.email);
    data.append("message", this.state.message);

    if (this.state.files) {
      var i;
      for (i = 0; i < this.state.files.length; i++) {
        var fileName = "file" + i;
        //console.log(fileName, this.state.files[i]);
        data.append(fileName, this.state.files[i]);
      }
    }

    const requestOptions = {
      method: "POST",
      body: data,
    };
    const response = await fetch("/sendmsg", requestOptions);
    const responseStatus = await response.status;

    console.log("Quotation sent with response: ", responseStatus);

    this.handleReset();

    this.setState({
      status: responseStatus,
    });

    const that = this;
    setTimeout(function () {
      that.setState({
        status: 0,
      });
    }, this.showStatusTimer * 1000);
  }

  renderAlertMsg(status) {
    const { t } = this.props;
    switch (status) {
      case 0:
        return "";
      case 200:
        return (
          <div className="flex-grow-1 alert alert-success alert-dismissible">
            <button type="button" className="close" data-dismiss="alert">
              &times;
            </button>
            {t("quotation.msg.success")}
          </div>
        );
      case 413:
        return (
          <div className="flex-grow-1 alert alert-danger alert-dismissible">
            <button type="button" className="close" data-dismiss="alert">
              &times;
            </button>
            {t("quotation.msg.toolarge")}
          </div>
        );
      default:
        return (
          <div className="flex-grow-1 alert alert-danger alert-dismissible">
            <button type="button" className="close" data-dismiss="alert">
              &times;
            </button>
            {t("quotation.msg.failed")}&nbsp;{status}
          </div>
        );
    }
  }

  render() {
    const { t } = this.props;
    return (
      <section id="quotation" className="p-md-3 p-4">
        <h4>
          {this.props.service}&nbsp;{t("quotation.title")}
        </h4>
        <div className="container form-wrapper p-0">
          <form
            id="quotationForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleReset}
          >
            <div className="d-flex">
              <div className="p-0 flex-grow-1">
                <div className="form-group input-group input-group-sm">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    // oninput="this.setCustomValidity('')"
                    placeholder={t("quotation.placeholder.name")}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="p-0 flex-grow-1 phone">
                <div className="form-group input-group input-group-sm">
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    value={this.state.phone}
                    onChange={this.handlePhoneChange}
                    placeholder={t("quotation.placeholder.phone")}
                    //pattern="^\d{3}-\d{3}-\d{4}$"
                    //title="xxx-xxx-xxxx"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="p-0 flex-grow-1">
                <div className="form-group input-group input-group-sm">
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    value={this.state.address}
                    onChange={this.handleAddressChange}
                    placeholder={t("quotation.placeholder.address")}
                    required
                  />
                </div>
              </div>
            </div>
            {/* <div className="d-flex">
              <div className="p-0 flex-grow-1 postalcode">
                <div className="form-group input-group input-group-sm">
                  <input
                    type="text"
                    className="form-control"
                    id="postalcode"
                    value={this.state.postalcode}
                    onChange={this.handlePostalcodeChange}
                    placeholder={t("quotation.placeholder.postalcode")}
                    pattern="^[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]"
                    title="A1A 1A1"
                    required
                  />
                </div>
              </div>
            </div> */}
            <div className="d-flex">
              <div className="p-0 flex-grow-1">
                <div className="form-group input-group input-group-sm">
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    placeholder={t("quotation.placeholder.email")}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="p-0 flex-grow-1">
                <div className="form-group input-group input-group-sm">
                  <textarea
                    className="form-control"
                    id="message"
                    value={this.state.message}
                    onChange={this.handleMessageChange}
                    rows="6"
                    placeholder={t("quotation.placeholder.message")}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="form-group input-group input-group-sm mb-0">
                {/* <input type="file" id="attachmentFiles" name="attachmentFiles" multiple/> */}
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="attachmentFiles"
                    accept="image/*"
                    onChange={this.handleAttachmentChange}
                    multiple
                  />
                  <label
                    className="custom-file-label col-form-label-sm"
                    htmlFor="attachmentFiles"
                  >
                    {this.state.files.length > 0
                      ? this.state.files.length === 1
                        ? this.state.files[0].name
                        : this.state.files.length +
                          t("quotation.placeholder.fileselected")
                      : t("quotation.placeholder.attachment")}
                  </label>
                </div>
              </div>
              <div className="attachmentNote">
                {t("quotation.attachment_note")}
              </div>
            </div>
            <div className="d-flex">
              <div className="p-0 flex-grow-1">
                <div className="btn-group" role="group">
                  <input
                    type="reset"
                    className="btn btn-warning reset"
                    value={t("quotation.button.clear")}
                  />
                </div>
                <div className="btn-group float-right" role="group">
                  {this.state.loading ? (
                    <button type="button" className="btn btn-primary" disabled>
                      <span className="spinner-border spinner-border-sm"></span>
                      {t("quotation.button.sending")}
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      {t("quotation.button.send")}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex status">
              {this.renderAlertMsg(this.state.status)}
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default withTranslation()(Quotation);
