import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Quotation from "../../quotation/quotation";
import "./about.css";

class About extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <div id="aboutus">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <section id="about">
                <h2>{t("main.aboutus.title")}</h2>
                <p>
                  {t("main.aboutus.p1_a")}
                  <strong>{t("main.aboutus.p1_b")}</strong>
                  {t("main.aboutus.p1_c")}
                  <a
                    href="https://goo.gl/maps/RMfCpJBj2JesoTSZA"
                    title="188 Steeles Ave East, Markham ON"
                  >
                    Yonge &amp; Steeles
                  </a>
                  {t("main.aboutus.p1_d")}
                </p>
                <p>{t("main.aboutus.p2")}</p>
                <p>{t("main.aboutus.p3")}</p>
                <p>{t("main.aboutus.p4")}</p>
                <p>
                  {t("main.aboutus.p5_a")}
                  <a href="#quotation">
                    <strong>{t("main.aboutus.p5_b")}</strong>
                  </a>
                  {t("main.aboutus.p5_c")}
                  <strong className="red">{t("main.aboutus.p5_d")}</strong>
                </p>
              </section>
            </div>
            <div className="col-md-5 col-lg-4 mb-3">
              <div className="sticky-top">
                <React.Fragment>
                  <Quotation service={t("main.aboutus.quotation")} />
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(About);
