import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ServiceIcon from "../../service/serviceicon";
import Highrise64 from "./images/highrise-64.png";
import Septictank64 from "./images/septictank-64.png";
import Unclogging64 from "./images/unclogging-64.png";
import Camera64 from "./images/camera-64.png";
import Waterpipe64 from "./images/waterpipe-64.png";
import Commercial64 from "./images/commercial-64.png";
import { Carousel } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./pastjobs.css";

class PastJobs extends Component {
  id = "pastjobs";
  state = {
    isImageOpen: false,
    slides: [],
    popups: [],
    imageIndex: 0,
  };
  render() {
    const { t } = this.props;
    const categories = [
      {
        id: "highrise",
        icon: Highrise64,
        slides: [
          require("./images/highrise/slides/slide1.png"),
          require("./images/highrise/slides/slide2.png"),
          require("./images/highrise/slides/slide3.png"),
        ],
        popups: [
          require("./images/highrise/popups/slide1.png"),
          require("./images/highrise/popups/slide2.png"),
          require("./images/highrise/popups/slide3.png"),
        ],
        title: t("pastjobs.highrise"),
      },
      {
        id: "septictank",
        icon: Septictank64,
        slides: [
          require("./images/septictank/slides/slide1.png"),
          require("./images/septictank/slides/slide2.png"),
          require("./images/septictank/slides/slide3.png"),
          require("./images/septictank/slides/slide4.png"),
          require("./images/septictank/slides/slide5.png"),
        ],
        popups: [
          require("./images/septictank/popups/slide1.png"),
          require("./images/septictank/popups/slide2.png"),
          require("./images/septictank/popups/slide3.png"),
          require("./images/septictank/popups/slide4.png"),
          require("./images/septictank/popups/slide5.png"),
        ],
        title: t("pastjobs.septictank"),
      },
      {
        id: "unclogging",
        icon: Unclogging64,
        slides: [
          require("./images/unclogging/slides/slide1.png"),
          require("./images/unclogging/slides/slide2.png"),
          require("./images/unclogging/slides/slide3.png"),
          require("./images/unclogging/slides/slide4.png"),
          require("./images/unclogging/slides/slide5.png"),
          require("./images/unclogging/slides/slide6.png"),
          require("./images/unclogging/slides/slide7.png"),
          require("./images/unclogging/slides/slide8.png"),
          require("./images/unclogging/slides/slide9.png"),
          require("./images/unclogging/slides/slide10.png"),
          require("./images/unclogging/slides/slide11.png"),
          require("./images/unclogging/slides/slide12.png"),
          require("./images/unclogging/slides/slide13.png"),
          require("./images/unclogging/slides/slide14.png"),
        ],
        popups: [
          require("./images/unclogging/popups/slide1.png"),
          require("./images/unclogging/popups/slide2.png"),
          require("./images/unclogging/popups/slide3.png"),
          require("./images/unclogging/popups/slide4.png"),
          require("./images/unclogging/popups/slide5.png"),
          require("./images/unclogging/popups/slide6.png"),
          require("./images/unclogging/popups/slide7.png"),
          require("./images/unclogging/popups/slide8.png"),
          require("./images/unclogging/popups/slide9.png"),
          require("./images/unclogging/popups/slide10.png"),
          require("./images/unclogging/popups/slide11.png"),
          require("./images/unclogging/popups/slide12.png"),
          require("./images/unclogging/popups/slide13.png"),
          require("./images/unclogging/popups/slide14.png"),
        ],
        title: t("pastjobs.unclogging"),
      },
      {
        id: "camerainspection",
        icon: Camera64,
        slides: [
          require("./images/camerainspection/slides/slide1.png"),
          require("./images/camerainspection/slides/slide2.png"),
          require("./images/camerainspection/slides/slide3.png"),
          require("./images/camerainspection/slides/slide4.png"),
          require("./images/camerainspection/slides/slide5.png"),
          require("./images/camerainspection/slides/slide6.png"),
          require("./images/camerainspection/slides/slide7.png"),
        ],
        popups: [
          require("./images/camerainspection/popups/slide1.png"),
          require("./images/camerainspection/popups/slide2.png"),
          require("./images/camerainspection/popups/slide3.png"),
          require("./images/camerainspection/popups/slide4.png"),
          require("./images/camerainspection/popups/slide5.png"),
          require("./images/camerainspection/popups/slide6.png"),
          require("./images/camerainspection/popups/slide7.png"),
        ],
        title: t("pastjobs.camerainspection"),
      },
      {
        id: "waterservicepipe",
        icon: Waterpipe64,
        slides: [
          require("./images/waterservicepipe/slides/slide1.png"),
          require("./images/waterservicepipe/slides/slide2.png"),
          require("./images/waterservicepipe/slides/slide3.png"),
          require("./images/waterservicepipe/slides/slide4.png"),
          require("./images/waterservicepipe/slides/slide5.png"),
          require("./images/waterservicepipe/slides/slide6.png"),
          require("./images/waterservicepipe/slides/slide7.png"),
          require("./images/waterservicepipe/slides/slide8.png"),
          require("./images/waterservicepipe/slides/slide9.png"),
          require("./images/waterservicepipe/slides/slide10.png"),
          require("./images/waterservicepipe/slides/slide11.png"),
          require("./images/waterservicepipe/slides/slide12.png"),
          require("./images/waterservicepipe/slides/slide13.png"),
          require("./images/waterservicepipe/slides/slide14.png"),
          require("./images/waterservicepipe/slides/slide15.png"),
          require("./images/waterservicepipe/slides/slide16.png"),
          require("./images/waterservicepipe/slides/slide17.png"),
          require("./images/waterservicepipe/slides/slide18.png"),
        ],
        popups: [
          require("./images/waterservicepipe/popups/slide1.png"),
          require("./images/waterservicepipe/popups/slide2.png"),
          require("./images/waterservicepipe/popups/slide3.png"),
          require("./images/waterservicepipe/popups/slide4.png"),
          require("./images/waterservicepipe/popups/slide5.png"),
          require("./images/waterservicepipe/popups/slide6.png"),
          require("./images/waterservicepipe/popups/slide7.png"),
          require("./images/waterservicepipe/popups/slide8.png"),
          require("./images/waterservicepipe/popups/slide9.png"),
          require("./images/waterservicepipe/popups/slide10.png"),
          require("./images/waterservicepipe/popups/slide11.png"),
          require("./images/waterservicepipe/popups/slide12.png"),
          require("./images/waterservicepipe/popups/slide13.png"),
          require("./images/waterservicepipe/popups/slide14.png"),
          require("./images/waterservicepipe/popups/slide15.png"),
          require("./images/waterservicepipe/popups/slide16.png"),
          require("./images/waterservicepipe/popups/slide17.png"),
          require("./images/waterservicepipe/popups/slide18.png"),
        ],
        title: t("pastjobs.waterservicepipe"),
      },
      {
        id: "industry",
        icon: Commercial64,
        slides: [
          require("./images/industry/slides/slide1.png"),
          require("./images/industry/slides/slide2.png"),
          require("./images/industry/slides/slide3.png"),
          require("./images/industry/slides/slide4.png"),
          require("./images/industry/slides/slide5.png"),
        ],
        popups: [
          require("./images/industry/popups/slide1.png"),
          require("./images/industry/popups/slide2.png"),
          require("./images/industry/popups/slide3.png"),
          require("./images/industry/popups/slide4.png"),
          require("./images/industry/popups/slide5.png"),
        ],
        title: t("pastjobs.industry"),
      },
    ];
    //console.log("CAT", categories[0].icon);
    return (
      <main id={this.id}>
        <div className="serviceIcons">
          <div className="container">
            <div className="row justify-content-center">
              {categories.map((category, index) => (
                <div
                  key={this.id + "-icon-" + index}
                  className="col-6 col-md-2"
                >
                  <ServiceIcon
                    href={"#" + category.id}
                    img={
                      <img
                        className="rounded-circle"
                        src={category.icon}
                        alt={category.title}
                      />
                    }
                    title={category.title}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {categories.map((category, index) => (
              <section
                id={category.id}
                key={this.id + "-" + index}
                className="col-12 col-md-6 w-100"
              >
                <div className="caption">
                  {category.title}
                </div>
                <Carousel>
                  {category.slides.map((img, imageIndex) => (
                    <Carousel.Item
                      key={this.id + "-carousel-" + index + "-" + imageIndex}
                    >
                      <img
                        key={this.id + "-img-" + index + "-" + imageIndex}
                        className="d-block"
                        src={img}
                        alt={"Slider " + index + "-" + imageIndex + " :" + img}
                        onClick={() =>
                          this.setState({
                            imageIndex: imageIndex,
                            popups: category.popups,
                            isImageOpen: true,
                          })
                        }
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </section>
            ))}
          </div>
          {this.state.isImageOpen && (
            <Lightbox
              mainSrc={this.state.popups[this.state.imageIndex]}
              nextSrc={
                this.state.popups[
                  (this.state.imageIndex + 1) % this.state.popups.length
                ]
              }
              prevSrc={
                this.state.popups[
                  (this.state.imageIndex + this.state.popups.length - 1) %
                    this.state.popups.length
                ]
              }
              onCloseRequest={() => this.setState({ isImageOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  imageIndex:
                    (this.state.imageIndex + this.state.popups.length - 1) %
                    this.state.popups.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  imageIndex:
                    (this.state.imageIndex + 1) % this.state.popups.length,
                })
              }
            />
          )}
        </div>
      </main>
    );
  }
}

export default withTranslation()(PastJobs);
