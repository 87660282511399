import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./service.css";

class Service extends Component {
  state = {
    isImageOpen: false,
    imageIndex: 0,
  };
  render() {
    return (
      <div id={this.props.id} className="service">
        <div className="card">
          <div className="card-header">
            <h4>
              {this.props.icon}&nbsp;{this.props.title}
            </h4>
          </div>
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-md-5 description">
                  {this.props.description}
                </div>
                <div className="col-md-7 p-0 m-0">
                  <Carousel>
                    {this.props.slides.map((img, index) => (
                      <Carousel.Item key={this.props.id + "-carousel-" + index}>
                        <img
                          key={this.props.id + "-img-" + index}
                          className="d-block h-100"
                          src={img}
                          alt={"Slider " + index + " :" + img}
                          onClick={() =>
                            this.setState({
                              isImageOpen: true,
                              imageIndex: index,
                            })
                          }
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  {this.state.isImageOpen && (
                    <Lightbox
                      mainSrc={this.props.popups[this.state.imageIndex]}
                      nextSrc={
                        this.props.popups[
                          (this.state.imageIndex + 1) % this.props.popups.length
                        ]
                      }
                      prevSrc={
                        this.props.popups[
                          (this.state.imageIndex +
                            this.props.popups.length -
                            1) %
                            this.props.popups.length
                        ]
                      }
                      onCloseRequest={() =>
                        this.setState({ isImageOpen: false })
                      }
                      onMovePrevRequest={() =>
                        this.setState({
                          imageIndex:
                            (this.state.imageIndex +
                              this.props.popups.length -
                              1) %
                            this.props.popups.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          imageIndex:
                            (this.state.imageIndex + 1) %
                            this.props.popups.length,
                        })
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Service;
