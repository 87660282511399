const menuItems = {
  "en-CA": [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Plumbing Service",
      link: "/plumbing",
      submenus: [
        {
          title: "Commercial",
          link: "/plumbing#commercial",
        },
        {
          title: "Residential",
          link: "/plumbing#residential",
        },
        {
          title: "New Construction",
          link: "/plumbing#newconstruction",
        },
        {
          title: "High-rise Building",
          link: "/plumbing#highrise",
        },
      ],
    },
    {
      title: "Drain & Sewer",
      link: "/drainsewer",
      submenus: [
        {
          title: "Camera Inspection",
          link: "/drainsewer#camerainspection",
        },
        {
          title: "Drain Unclogging",
          link: "/drainsewer#drainunclogging",
        },
        {
          title: "Drain Replacement",
          link: "/drainsewer#drainreplacement",
        },
        {
          title: "High Pressure Jetting",
          link: "/drainsewer#highpressure",
        },
      ],
    },
    {
      title: "Water Service",
      link: "/water",
      submenus: [
        {
          title: "Lead Pipe Replacement",
          link: "/water#leadpipereplacement",
        },
        {
          title: "Water Service Pipe Leaking",
          link: "/water#pipeleaking",
        },
        {
          title: "Water Service Pipe Install",
          link: "/water#pipeinstall",
        },
      ],
    },
    {
      title: "Waterproof & Flooding Protection",
      link: "/waterproof",
      submenus: [
        {
          title: "Waterproofing",
          link: "/waterproof#waterproofing",
        },
        {
          title: "Backwater Valve",
          link: "/waterproof#backwatervalve",
        },
        {
          title: "Sump Pump",
          link: "/waterproof#sumppump",
        },
      ],
    },
    {
      title: "Water Damage Restoration",
      link: "/waterdamage",
    },
  ],
  "zh-CN": [
    {
      title: "首页",
      link: "/",
    },
    {
      title: "管道服务",
      link: "/plumbing",
      submenus: [
        {
          title: "商业服务",
          link: "/plumbing#commercial",
        },
        {
          title: "住宅服务",
          link: "/plumbing#residential",
        },
        {
          title: "新建物业",
          link: "/plumbing#newconstruction",
        },
        {
          title: "高层物业",
          link: "/plumbing#highrise",
        },
      ],
    },
    {
      title: "下水道服务",
      link: "/drainsewer",
      submenus: [
        {
          title: "电脑视像检查",
          link: "/drainsewer#camerainspection",
        },
        {
          title: "机器疏通管道",
          link: "/drainsewer#drainunclogging",
        },
        {
          title: "更换地下排水管",
          link: "/drainsewer#drainreplacement",
        },
        {
          title: "高压冲洗管道",
          link: "/drainsewer#highpressure",
        },
      ],
    },
    {
      title: "供水服务",
      link: "/water",
      submenus: [
        {
          title: "地下铅管更换",
          link: "/water#leadpipereplacement",
        },
        {
          title: "维修供水管漏水",
          link: "/water#pipeleaking",
        },
        {
          title: "安装供水管",
          link: "/water#pipeinstall",
        },
      ],
    },
    {
      title: "防水和水患防护",
      link: "/waterproof",
      submenus: [
        {
          title: "地下室防水和补漏",
          link: "/waterproof#waterproofing",
        },
        {
          title: "安装倒水阀",
          link: "/waterproof#backwatervalve",
        },
        {
          title: "集水井和抽水泵",
          link: "/waterproof#sumppump",
        },
      ],
    },
    {
      title: "水害修复服务",
      link: "/waterdamage",
    },
  ]
};

export function getMenus(locale) {
  if (menuItems[locale]) {
    return menuItems[locale];
  } else {
    return menuItems["en-CA"];
  }
}