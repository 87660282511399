import React, { Component } from "react";
import slide1 from "./images/slide1.png";
import slide2 from "./images/slide2.png";
import slide3 from "./images/slide3.png";
import slide4 from "./images/slide4.png";
// import slide5 from "./images/slide5.png";
// import slide6 from "./images/slide6.png";
import { Carousel } from "react-bootstrap";
import "./slider.css";

class Slider extends Component {
  state = {};
  render() {
    return (
      <Carousel id="mainSlider">
        <Carousel.Item>
          <img className="d-block" src={slide1} alt="slide 1" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block" src={slide2} alt="slide 2" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block" src={slide3} alt="slide 3" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block" src={slide4} alt="slide 4" />
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default Slider;
