import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ServiceIcon from "../../service/serviceicon";
import camera32 from "./images/camera-32.png";
import camera64 from "./images/camera-64.png";
import unclogging32 from "./images/unclogging-32.png";
import unclogging64 from "./images/unclogging-64.png";
import replacement32 from "./images/replacement-32.png";
import replacement64 from "./images/replacement-64.png";
import highpressure32 from "./images/highpressure-32.png";
import highpressure64 from "./images/highpressure-64.png";
import Service from "../../service/service";
import Quotation from "../../quotation/quotation";
import "./drainsewer.css";

class DrainSewer extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <main id="drainsewer">
        <div className="serviceIcons">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6 col-md-3">
                <ServiceIcon
                  href="/drainsewer#camerainspection"
                  img={
                    <img
                      className="rounded-circle"
                      src={camera64}
                      alt={camera64}
                    />
                  }
                  title={t("drainsewer.camerainspection.title")}
                />
              </div>
              <div className="col-6 col-md-3">
                <ServiceIcon
                  href="/drainsewer#drainunclogging"
                  img={
                    <img
                      className="rounded-circle"
                      src={unclogging64}
                      alt={unclogging64}
                    />
                  }
                  title={t("drainsewer.unclogging.title")}
                />
              </div>
              <div className="col-6 col-md-3">
                <ServiceIcon
                  href="/drainsewer#drainreplacement"
                  img={
                    <img
                      className="rounded-circle"
                      src={replacement64}
                      alt={replacement64}
                    />
                  }
                  title={t("drainsewer.replacement.title")}
                />
              </div>
              <div className="col-6 col-md-3">
                <ServiceIcon
                  href="/drainsewer#highpressure"
                  img={
                    <img
                      className="rounded-circle"
                      src={highpressure64}
                      alt={highpressure64}
                    />
                  }
                  title={t("drainsewer.highpressure.title")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <Service
                id="camerainspection"
                icon={
                  <img
                    className="serviceTitleIcon"
                    src={camera32}
                    alt={camera32}
                  />
                }
                title={t("drainsewer.camerainspection.title")}
                slides={[
                  require("./images/camerainspection/slides/slide1.png"),
                  require("./images/camerainspection/slides/slide2.png"),
                  require("./images/camerainspection/slides/slide3.png"),
                  require("./images/camerainspection/slides/slide4.png"),
                  require("./images/camerainspection/slides/slide5.png"),
                  require("./images/camerainspection/slides/slide6.png"),
                ]}
                popups={[
                  require("./images/camerainspection/popups/slide1.png"),
                  require("./images/camerainspection/popups/slide2.png"),
                  require("./images/camerainspection/popups/slide3.png"),
                  require("./images/camerainspection/popups/slide4.png"),
                  require("./images/camerainspection/popups/slide5.png"),
                  require("./images/camerainspection/popups/slide6.png"),
                ]}
                description={
                  <div>
                    <p>{t("drainsewer.camerainspection.p1")}</p>
                  </div>
                }
              />
              <Service
                id="drainunclogging"
                icon={
                  <img
                    className="serviceTitleIcon"
                    src={unclogging32}
                    alt={unclogging32}
                  />
                }
                title={t("drainsewer.unclogging.title")}
                slides={[
                  require("./images/drainunclogging/slides/slide1.png"),
                  require("./images/drainunclogging/slides/slide2.png"),
                  require("./images/drainunclogging/slides/slide3.png"),
                  require("./images/drainunclogging/slides/slide4.png"),
                  require("./images/drainunclogging/slides/slide5.png"),
                  require("./images/drainunclogging/slides/slide6.png"),
                ]}
                popups={[
                  require("./images/drainunclogging/popups/slide1.png"),
                  require("./images/drainunclogging/popups/slide2.png"),
                  require("./images/drainunclogging/popups/slide3.png"),
                  require("./images/drainunclogging/popups/slide4.png"),
                  require("./images/drainunclogging/popups/slide5.png"),
                  require("./images/drainunclogging/popups/slide6.png"),
                ]}
                description={
                  <div>
                    <p>{t("drainsewer.unclogging.p1")}</p>
                  </div>
                }
              />
              <Service
                id="drainreplacement"
                icon={
                  <img
                    className="serviceTitleIcon"
                    src={replacement32}
                    alt={replacement32}
                  />
                }
                title={t("drainsewer.replacement.title")}
                slides={[
                  require("./images/drainreplacement/slides/slide1.png"),
                  require("./images/drainreplacement/slides/slide2.png"),
                  require("./images/drainreplacement/slides/slide3.png"),
                  require("./images/drainreplacement/slides/slide4.png"),
                  require("./images/drainreplacement/slides/slide5.png"),
                  require("./images/drainreplacement/slides/slide6.png"),
                  require("./images/drainreplacement/slides/slide9.png"),
                  require("./images/drainreplacement/slides/slide7.png"),
                  require("./images/drainreplacement/slides/slide8.png"),
                ]}
                popups={[
                  require("./images/drainreplacement/popups/slide1.png"),
                  require("./images/drainreplacement/popups/slide2.png"),
                  require("./images/drainreplacement/popups/slide3.png"),
                  require("./images/drainreplacement/popups/slide4.png"),
                  require("./images/drainreplacement/popups/slide5.png"),
                  require("./images/drainreplacement/popups/slide6.png"),
                  require("./images/drainreplacement/popups/slide9.png"),
                  require("./images/drainreplacement/popups/slide7.png"),
                  require("./images/drainreplacement/popups/slide8.png"),
                ]}
                description={
                  <div>
                    <p>{t("drainsewer.replacement.p1")}</p>
                  </div>
                }
              />
              <Service
                id="highpressure"
                icon={
                  <img
                    className="serviceTitleIcon"
                    src={highpressure32}
                    alt={highpressure32}
                  />
                }
                title={t("drainsewer.highpressure.title")}
                slides={[
                  require("./images/highpressure/slides/slide1.png"),
                  require("./images/highpressure/slides/slide2.png"),
                  require("./images/highpressure/slides/slide3.png"),
                  require("./images/highpressure/slides/slide4.png"),
                  require("./images/highpressure/slides/slide5.png"),
                  require("./images/highpressure/slides/slide7.png"),
                  require("./images/highpressure/slides/slide6.png"),
                ]}
                popups={[
                  require("./images/highpressure/popups/slide1.png"),
                  require("./images/highpressure/popups/slide2.png"),
                  require("./images/highpressure/popups/slide3.png"),
                  require("./images/highpressure/popups/slide4.png"),
                  require("./images/highpressure/popups/slide5.png"),
                  require("./images/highpressure/popups/slide7.png"),
                  require("./images/highpressure/popups/slide6.png"),
                ]}
                description={
                  <div>
                    <p>{t("drainsewer.highpressure.p1")}</p>
                  </div>
                }
              />
            </div>
            <div className="col-md-5 col-lg-4 p-0">
              <div className="sticky-top">
                <React.Fragment>
                  <Quotation service={t("drainsewer.title")} />
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(DrainSewer);
