import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Service from "../../service/service";
import Quotation from "../../quotation/quotation";
import { FaHouseDamage } from "react-icons/fa";
import "./waterdamage.css";

class WaterDamage extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <main id="water">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <Service
                id="waterdamagerestoration"
                icon={<FaHouseDamage />}
                title={t("waterdamage.title")}
                slides={[
                  require("./images/slides/slide1.png"),
                  require("./images/slides/slide2.png"),
                  require("./images/slides/slide3.png"),
                  require("./images/slides/slide4.png"),
                  require("./images/slides/slide5.png"),
                  require("./images/slides/slide6.png"),
                ]}
                popups={[
                  require("./images/popups/slide1.png"),
                  require("./images/popups/slide2.png"),
                  require("./images/popups/slide3.png"),
                  require("./images/popups/slide4.png"),
                  require("./images/popups/slide5.png"),
                  require("./images/popups/slide6.png"),
                ]}
                description={
                  <div>
                    <p>
                      {t("waterdamage.p1_a")}
                      <strong>{t("waterdamage.p1_b")}</strong>
                      {t("waterdamage.p1_c")}
                    </p>
                  </div>
                }
              />
            </div>
            <div className="col-md-5 col-lg-4">
              <div className="sticky-top">
                <React.Fragment>
                  <Quotation service={t("waterdamage.title")} />
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(WaterDamage);
