import React, { Component } from "react";
import i18n from "../../i18n"
import { withTranslation } from "react-i18next";
import { getMenus } from "../menus.js";
import "./footer.css";

class Footer extends Component {
  state = {
    year: new Date().getFullYear(),
  };
  render() {
    const pjson = require('../../../package.json');
    const menus = getMenus(i18n.language);
    const { t } = this.props;
    return (
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="menus container p-0">
                <div className="row">
                  {menus.map((menu, index) => (
                    <div className="col-6 col-md-4 col-lg-2 menuBlock" key={"f-menu-" + index}>
                      <h6>
                        <a href={menu.link}>{menu.title}</a>
                      </h6>
                      {menu.submenus && (
                        <ul>
                          {menu.submenus.map((submenu, subIndex) => (
                            <li key={"f-menu-sub-" + subIndex}>
                              <a
                                href={submenu.link}
                                key={"f-menu-sub-link-" + subIndex}
                              >
                                {submenu.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                      {index === 0 && (
                        <ul>
                          <li>
                      <a href="/#about">{t("main.aboutus.title")}</a>
                          </li>
                          <li>
                      <a href="/#quotation">{t("main.aboutus.quotation")}{t("quotation.title")}</a>
                          </li>
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-3 p-0">
              <h4 className="company">{process.env.REACT_APP_COMPANY}</h4>
              <div className="d-flex flex-column contact">
                <address className="address">
                  188 Steeles Ave East
                  <br />
                  Markham ON, L3T 1A5
                </address>
                <p className="servicehours">
                  <span>{t("footer.servicehours")}</span>
                </p>
                <p className="phone">
                  <a href="tel:416-508-0999">
                    {t("footer.phone1")}: 416-508-0999
                  </a>
                </p>
                <p className="phone">
                  <a href="tel:905-889-0999">
                    {t("footer.phone2")}: 905-889-0999
                  </a>
                </p>
                <p className="email">
                  <a href="mailto:info@modernplumbing.ca" className="email">
                    {t("footer.email")}:&nbsp;info@modernplumbing.ca
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container d-flex">
            <div className="p-6">
              <p>
                {t("footer.copyright")} &copy; {this.state.year}{" "}
                <b>{process.env.REACT_APP_COMPANY}</b>&nbsp;
                {t("footer.allrightsreserved")}
              </p>
            </div>
            <div className="poweredby p-6 ml-auto">
              <p>
                {t("footer.poweredby")}&nbsp;
                <a href={process.env.REACT_APP_POWEREDBYURL}>
                  {process.env.REACT_APP_POWEREDBY}
                </a>
                &nbsp;v{pjson.version}
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer);
