import React, { Component } from "react";
import "./serviceicon.css";

class ServiceIcon extends Component {
  state = {};
  render() {
    return (
      <div className="serviceIcon container">
        <div className="row">
          <div className="col card">
            <div className="card-body">
              <a href={this.props.href}>
                {this.props.img}
                <p className="service-title">{this.props.title}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceIcon;
