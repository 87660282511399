import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ServiceIcon from "../../service/serviceicon";
import { GiLeadPipe, GiLeak, GiPipes } from "react-icons/gi";
import Service from "../../service/service";
import Quotation from "../../quotation/quotation";
import "./water.css";

class Water extends Component {
  state = {};
  render() {
    const serviceIconSize = "64px";
    const { t } = this.props;
    return (
      <main id="water">
        <div className="serviceIcons">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-4 col-md-3">
                <ServiceIcon
                  href="/water#leadpipereplacement"
                  img={
                    <GiLeadPipe
                      className="rounded-circle"
                      size={serviceIconSize}
                    />
                  }
                  title={t("water.leadpipe.title")}
                />
              </div>
              <div className="col-4 col-md-3">
                <ServiceIcon
                  href="/water#pipeleaking"
                  img={
                    <GiLeak className="rounded-circle" size={serviceIconSize} />
                  }
                  title={t("water.pipeleak.title")}
                />
              </div>
              <div className="col-4 col-md-3">
                <ServiceIcon
                  href="/water#pipeinstall"
                  img={
                    <GiPipes
                      className="rounded-circle"
                      size={serviceIconSize}
                    />
                  }
                  title={t("water.pipeinstall.title")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <Service
                id="leadpipereplacement"
                icon={<GiLeadPipe />}
                title={t("water.leadpipe.title")}
                slides={[
                  require("./images/leadpipereplacement/slides/slide1.png"),
                  require("./images/leadpipereplacement/slides/slide2.png"),
                  require("./images/leadpipereplacement/slides/slide3.png"),
                  require("./images/leadpipereplacement/slides/slide4.png"),
                  require("./images/leadpipereplacement/slides/slide5.png"),
                  require("./images/leadpipereplacement/slides/slide6.png"),
                ]}
                popups={[
                  require("./images/leadpipereplacement/popups/slide1.png"),
                  require("./images/leadpipereplacement/popups/slide2.png"),
                  require("./images/leadpipereplacement/popups/slide3.png"),
                  require("./images/leadpipereplacement/popups/slide4.png"),
                  require("./images/leadpipereplacement/popups/slide5.png"),
                  require("./images/leadpipereplacement/popups/slide6.png"),
                ]}
                description={
                  <div>
                    <p>{t("water.leadpipe.p1")}</p>
                  </div>
                }
              />
              <Service
                id="pipeleaking"
                icon={<GiLeak />}
                title={t("water.pipeleak.title")}
                slides={[
                  require("./images/pipeleaking/slides/slide1.png"),
                  require("./images/pipeleaking/slides/slide2.png"),
                  require("./images/pipeleaking/slides/slide3.png"),
                  require("./images/pipeleaking/slides/slide4.png"),
                  require("./images/pipeleaking/slides/slide5.png"),
                  require("./images/pipeleaking/slides/slide6.png"),
                ]}
                popups={[
                  require("./images/pipeleaking/popups/slide1.png"),
                  require("./images/pipeleaking/popups/slide2.png"),
                  require("./images/pipeleaking/popups/slide3.png"),
                  require("./images/pipeleaking/popups/slide4.png"),
                  require("./images/pipeleaking/popups/slide5.png"),
                  require("./images/pipeleaking/popups/slide6.png"),
                ]}
                description={
                  <div>
                    <p>{t("water.pipeleak.p1")}</p>
                  </div>
                }
              />
              <Service
                id="pipeinstall"
                icon={<GiPipes />}
                title={t("water.pipeinstall.title")}
                slides={[
                  require("./images/pipeinstall/slides/slide1.png"),
                  require("./images/pipeinstall/slides/slide2.png"),
                  require("./images/pipeinstall/slides/slide3.png"),
                  require("./images/pipeinstall/slides/slide4.png"),
                  require("./images/pipeinstall/slides/slide5.png"),
                  require("./images/pipeinstall/slides/slide6.png"),
                  require("./images/pipeinstall/slides/slide7.png"),
                  require("./images/pipeinstall/slides/slide8.png"),
                ]}
                popups={[
                  require("./images/pipeinstall/popups/slide1.png"),
                  require("./images/pipeinstall/popups/slide2.png"),
                  require("./images/pipeinstall/popups/slide3.png"),
                  require("./images/pipeinstall/popups/slide4.png"),
                  require("./images/pipeinstall/popups/slide5.png"),
                  require("./images/pipeinstall/popups/slide6.png"),
                  require("./images/pipeinstall/popups/slide7.png"),
                  require("./images/pipeinstall/popups/slide8.png"),
                ]}
                description={
                  <div>
                    <p>{t("water.pipeinstall.p1")}</p>
                    <ul>
                      <li>{t("water.pipeinstall.p1_a")}</li>
                      <li>{t("water.pipeinstall.p1_b")}</li>
                      <li>{t("water.pipeinstall.p1_c")}</li>
                      <li>{t("water.pipeinstall.p1_d")}</li>
                    </ul>
                  </div>
                }
              />
            </div>
            <div className="col-md-5 col-lg-4">
              <div className="sticky-top">
                <React.Fragment>
                  <Quotation service={t("water.title")} />
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(Water);
