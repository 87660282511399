import React, { Component } from "react";
import Icon from "./up-24.png";
import "./scrollbutton.css";

class ScrollButton extends Component {
  state = {};

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <button
        title="Back to top"
        className="scroll"
        onClick={() => {
          this.scrollToTop();
        }}
      >
        <img src={Icon} alt="Back to Top" />
      </button>
    );
  }
}

export default ScrollButton;
