import "open-iconic/font/css/open-iconic-bootstrap.min.css";
import "./App.css";

// import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/header";
import Main from "./components/main/main";
import Plumbing from "./components/services/plumbing/plumbing";
import DrainSewer from "./components/services/drainsewer/drainsewer";
import Water from "./components/services/water/water";
import WaterProof from "./components/services/waterproof/waterproof";
import WaterDamage from "./components/services/waterdamage/waterdamage";
import PastJobs from "./components/services/pastjobs/pastjobs";
import ScrollButton from "./components/scrollbutton/scrollbutton";
import Footer from "./components/footer/footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/plumbing" element={<Plumbing />} />
          <Route path="/drainsewer" element={<DrainSewer />} />
          <Route path="/water" element={<Water />} />
          <Route path="/waterproof" element={<WaterProof />} />
          <Route path="/waterdamage" element={<WaterDamage />} />
          <Route path="/pastjobs" element={<PastJobs />} />
        </Routes>
      </Router>
      <ScrollButton />
      <Footer />
    </div>
  );
}

export default App;
