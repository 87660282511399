import React, { Component } from "react";
import i18n from "../../i18n"
import { withTranslation } from "react-i18next";
import { getMenus } from "../menus.js";
import logo from "./logo.png";
import WhatsappIcon from "./whatsapp";
import "./header.css";

function LangSwitch() {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="langswitch btn-group btn-group-sm pt-1">
      <button
        onClick={() => changeLanguage("en-CA")}
        type="button"
        className="btn btn-warning"
        disabled={i18n.language === "en-CA"}
      >
        Eng
      </button>
      <button
        onClick={() => changeLanguage("zh-CN")}
        type="button"
        className="btn btn-warning"
        disabled={i18n.language === "zh-CN"}
      >
        简中
      </button>
    </div>
  );
}

class Header extends Component {
  state = {};
  render() {
    const menus = getMenus(i18n.language);
    const { t } = this.props;
    return (
      <header id="header">
        <div className="container">
          <div className="banner d-flex">
            <div className="flex-grow-1">
              <a className="navbar-brand logo" href="/">
                <img
                  src={logo}
                  alt={process.env.REACT_APP_COMPANY}
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="d-flex flex-column contact">
              <div className="d-flex flex-row-reverse">
                <LangSwitch />
              </div>
              <div className="d-lg-block d-none pt-lg-1 pt-0">
                <div className="d-flex flex-row-reverse">
                  <div className="onlinequotation">
                    <a
                      className="btn btn-primary"
                      role="button"
                      href="#quotation"
                    >
                      <strong>{t("header.pricestimate")}</strong>
                    </a>
                  </div>
                  <div className="phone mr-1">
                    <a
                      className="btn btn-danger"
                      role="button"
                      href="tel:416-508-0999"
                    >
                      <strong>{t("header.callnow")}: 416-508-0999</strong>
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row-reverse pt-0 pt-md-2 pt-lg-1">
                <div className="socialmedia">
                  <a href="https://wa.me/14165080999" className="ml-1">
                    <WhatsappIcon />
                  </a>
                </div>
                <div className="slogan pt-1 pt-md-0">
                  {t("header.licensedinsured")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg" id="ftco-navbar">
          <div className="d-flex">
            <div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#ftco-nav"
                aria-controls="ftco-nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="oi oi-menu"></span>
              </button>
            </div>
            <div className="d-lg-none">
              <a
                className="btn btn-danger btn-sm ml-1"
                role="button"
                href="tel:416-508-0999"
              >
                <strong>416-508-0999</strong>
              </a>
            </div>
            <div className="d-lg-none">
              <a
                className="btn btn-primary btn-sm ml-1"
                role="button"
                href="#quotation"
              >
                {t("header.pricestimate")}
              </a>
            </div>
          </div>
          <div className="container">
            <div className="collapse navbar-collapse" id="ftco-nav">
              <ul className="navbar-nav mx-auto">
                {menus.map((menu, index) => (
                  <li
                    key={"menu-" + index}
                    className={
                      (menu.submenus ? "nav-item dropdown" : "nav-item") +
                      (menu.link === window.location.pathname ? " active" : "")
                    }
                  >
                    <a
                      href={menu.link}
                      className={
                        menu.submenus ? "nav-link dropdown-toggle" : "nav-link"
                      }
                      key={"menu-link-" + index}
                    >
                      {menu.title}
                    </a>
                    {menu.submenus && (
                      <div className="dropdown-menu" key={"menu-sub-" + index}>
                        {menu.submenus.map((submenu, subIndex) => (
                          <a
                            className="dropdown-item"
                            href={submenu.link}
                            key={"menu-sub-link" + subIndex}
                          >
                            {submenu.title}
                          </a>
                        ))}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default withTranslation()(Header);
