import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ServiceIcon from "../../service/serviceicon";
import commercial32 from "./images/commercial-32.png";
import commercial64 from "./images/commercial-64.png";
import residential32 from "./images/residential-32.png";
import residential64 from "./images/residential-64.png";
import newconstruction32 from "./images/newconstruction-32.png";
import newconstruction64 from "./images/newconstruction-64.png";
import highrise32 from "./images/highrise-32.png";
import highrise64 from "./images/highrise-64.png";
import Service from "../../service/service";
import Quotation from "../../quotation/quotation";
import "./plumbing.css";

class Plumbing extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <main id="plumbing">
        <div className="serviceIcons">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6 col-md-3">
                <ServiceIcon
                  href="/plumbing#commercial"
                  img={
                    <img
                      className="rounded-circle"
                      src={commercial64}
                      alt={commercial64}
                    />
                  }
                  title={t("plumbing.commercial.title")}
                />
              </div>
              <div className="col-6 col-md-3">
                <ServiceIcon
                  href="/plumbing#residential"
                  img={
                    <img
                      className="rounded-circle"
                      src={residential64}
                      alt={residential64}
                    />
                  }
                  title={t("plumbing.residential.title")}
                />
              </div>
              <div className="col-6 col-md-3">
                <ServiceIcon
                  href="/plumbing#newconstruction"
                  img={
                    <img
                      className="rounded-circle"
                      src={newconstruction64}
                      alt={newconstruction64}
                    />
                  }
                  title={t("plumbing.newconstruction.title")}
                />
              </div>
              <div className="col-6 col-md-3">
                <ServiceIcon
                  href="/plumbing#highrise"
                  img={
                    <img
                      className="rounded-circle"
                      src={highrise64}
                      alt={highrise64}
                    />
                  }
                  title={t("plumbing.highrise.title")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <Service
                id="commercial"
                icon={
                  <img
                    className="serviceTitleIcon"
                    src={commercial32}
                    alt={commercial32}
                  />
                }
                title={t("plumbing.commercial.title")}
                slides={[
                  require("./images/commercial/slides/slide1.png"),
                  require("./images/commercial/slides/slide2.png"),
                  require("./images/commercial/slides/slide3.png"),
                  require("./images/commercial/slides/slide4.png"),
                  require("./images/commercial/slides/slide5.png"),
                  require("./images/commercial/slides/slide6.png"),
                ]}
                popups={[
                  require("./images/commercial/popups/slide1.png"),
                  require("./images/commercial/popups/slide2.png"),
                  require("./images/commercial/popups/slide3.png"),
                  require("./images/commercial/popups/slide4.png"),
                  require("./images/commercial/popups/slide5.png"),
                  require("./images/commercial/popups/slide6.png"),
                ]}
                description={
                  <div>
                    <p>
                      {t("plumbing.commercial.p1")}
                    </p>
                    <p>
                      {t("plumbing.commercial.p2_a")}
                      <strong>{t("plumbing.commercial.p2_b")}</strong>
                      {t("plumbing.commercial.p2_c")}
                    </p>
                  </div>
                }
              />
              <Service
                id="residential"
                icon={
                  <img
                    className="serviceTitleIcon"
                    src={residential32}
                    alt={residential32}
                  />
                }
                title={t("plumbing.residential.title")}
                slides={[
                  require("./images/residential/slides/slide1.png"),
                  require("./images/residential/slides/slide2.png"),
                  require("./images/residential/slides/slide3.png"),
                  require("./images/residential/slides/slide4.png"),
                  require("./images/residential/slides/slide5.png"),
                ]}
                popups={[
                  require("./images/residential/popups/slide1.png"),
                  require("./images/residential/popups/slide2.png"),
                  require("./images/residential/popups/slide3.png"),
                  require("./images/residential/popups/slide4.png"),
                  require("./images/residential/popups/slide5.png"),
                ]}
                description={
                  <div>
                    <p>{t("plumbing.residential.p1")}</p>
                    <p>{t("plumbing.residential.p2")}</p>
                  </div>
                }
              />
              <Service
                id="newconstruction"
                icon={
                  <img
                    className="serviceTitleIcon"
                    src={newconstruction32}
                    alt={newconstruction32}
                  />
                }
                title={t("plumbing.newconstruction.title")}
                slides={[
                  require("./images/newconstruction/slides/slide1.png"),
                  require("./images/newconstruction/slides/slide2.png"),
                  require("./images/newconstruction/slides/slide3.png"),
                  require("./images/newconstruction/slides/slide4.png"),
                  require("./images/newconstruction/slides/slide5.png"),
                  require("./images/newconstruction/slides/slide6.png"),
                  require("./images/newconstruction/slides/slide7.png"),
                  require("./images/newconstruction/slides/slide8.png"),
                  require("./images/newconstruction/slides/slide9.png"),
                ]}
                popups={[
                  require("./images/newconstruction/popups/slide1.png"),
                  require("./images/newconstruction/popups/slide2.png"),
                  require("./images/newconstruction/popups/slide3.png"),
                  require("./images/newconstruction/popups/slide4.png"),
                  require("./images/newconstruction/popups/slide5.png"),
                  require("./images/newconstruction/popups/slide6.png"),
                  require("./images/newconstruction/popups/slide7.png"),
                  require("./images/newconstruction/popups/slide8.png"),
                  require("./images/newconstruction/popups/slide9.png"),
                ]}
                description={
                  <div>
                    <p>{t("plumbing.newconstruction.p1")}</p>
                  </div>
                }
              />
              <Service
                id="highrise"
                icon={
                  <img
                    className="serviceTitleIcon"
                    src={highrise32}
                    alt={highrise32}
                  />
                }
                title={t("plumbing.highrise.title")}
                slides={[
                  require("./images/highrise/slides/slide1.png"),
                  require("./images/highrise/slides/slide2.png"),
                  require("./images/highrise/slides/slide3.png"),
                ]}
                popups={[
                  require("./images/highrise/popups/slide1.png"),
                  require("./images/highrise/popups/slide2.png"),
                  require("./images/highrise/popups/slide3.png"),
                ]}
                description={
                  <div>
                    <p>{t("plumbing.highrise.p1")}</p>
                  </div>
                }
              />
            </div>
            <div className="col-md-5 col-lg-4">
              <div className="sticky-top">
                <React.Fragment>
                  <Quotation service={t("plumbing.title")} />
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(Plumbing);
