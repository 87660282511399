import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ServiceIcon from "../../service/serviceicon";
import { GiValve } from "react-icons/gi";
import { WiRaindrops } from "react-icons/wi";
import pumpIcon32 from "./pump-32.png";
import pumpIcon64 from "./pump-64.png";
import Service from "../../service/service";
import Quotation from "../../quotation/quotation";
import "./waterproof.css";

class WaterProof extends Component {
  state = {};
  render() {
    const serviceIconSize = "64px";
    const { t } = this.props;
    return (
      <main id="waterproof">
        <div className="serviceIcons">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-4 col-md-3">
                <ServiceIcon
                  href="/waterproof#waterproofing"
                  img={
                    <WiRaindrops
                      className="rounded-circle"
                      size={serviceIconSize}
                    />
                  }
                  title={t("waterproof.waterproofing.title")}
                />
              </div>
              <div className="col-4 col-md-3">
                <ServiceIcon
                  href="/waterproof#backwatervalve"
                  img={
                    <GiValve
                      className="rounded-circle"
                      size={serviceIconSize}
                    />
                  }
                  title={t("waterproof.backwatervalve.title")}
                />
              </div>
              <div className="col-4 col-md-3">
                <ServiceIcon
                  href="/waterproof#sumppump"
                  img={
                    <img
                      className="rounded-circle"
                      src={pumpIcon64}
                      alt={pumpIcon64}
                    />
                  }
                  title={t("waterproof.sumppump.title")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <Service
                id="waterproofing"
                icon={<WiRaindrops />}
                title={t("waterproof.waterproofing.title")}
                slides={[
                  require("./images/waterproofing/slides/slide1.png"),
                  require("./images/waterproofing/slides/slide2.png"),
                  require("./images/waterproofing/slides/slide3.png"),
                  require("./images/waterproofing/slides/slide4.png"),
                  require("./images/waterproofing/slides/slide5.png"),
                  require("./images/waterproofing/slides/slide6.png"),
                  require("./images/waterproofing/slides/slide7.png"),
                  require("./images/waterproofing/slides/slide8.png"),
                ]}
                popups={[
                  require("./images/waterproofing/popups/slide1.png"),
                  require("./images/waterproofing/popups/slide2.png"),
                  require("./images/waterproofing/popups/slide3.png"),
                  require("./images/waterproofing/popups/slide4.png"),
                  require("./images/waterproofing/popups/slide5.png"),
                  require("./images/waterproofing/popups/slide6.png"),
                  require("./images/waterproofing/popups/slide7.png"),
                  require("./images/waterproofing/popups/slide8.png"),
                ]}
                description={
                  <div>
                    <p>{t("waterproof.waterproofing.p1")}</p>
                    <ul>
                      <li>{t("waterproof.waterproofing.p1_a")}</li>
                      <li>{t("waterproof.waterproofing.p1_b")}</li>
                      <li>{t("waterproof.waterproofing.p1_c")}</li>
                      <li>{t("waterproof.waterproofing.p1_d")}</li>
                      <li>{t("waterproof.waterproofing.p1_e")}</li>
                    </ul>
                  </div>
                }
              />
              <Service
                id="backwatervalve"
                icon={<GiValve />}
                title={t("waterproof.backwatervalve.title")}
                slides={[
                  require("./images/backwatervalve/slides/slide1.png"),
                  require("./images/backwatervalve/slides/slide2.png"),
                  require("./images/backwatervalve/slides/slide3.png"),
                  require("./images/backwatervalve/slides/slide4.png"),
                  require("./images/backwatervalve/slides/slide5.png"),
                  require("./images/backwatervalve/slides/slide6.png"),
                  require("./images/backwatervalve/slides/slide7.png"),
                ]}
                popups={[
                  require("./images/backwatervalve/popups/slide1.png"),
                  require("./images/backwatervalve/popups/slide2.png"),
                  require("./images/backwatervalve/popups/slide3.png"),
                  require("./images/backwatervalve/popups/slide4.png"),
                  require("./images/backwatervalve/popups/slide5.png"),
                  require("./images/backwatervalve/popups/slide6.png"),
                  require("./images/backwatervalve/popups/slide7.png"),
                ]}
                description={
                  <div>
                    <p>{t("waterproof.backwatervalve.p1")}</p>
                    <p>
                      {t("waterproof.backwatervalve.p2_a")}
                      <strong>$1,250</strong>
                      {t("waterproof.backwatervalve.p2_b")}
                      <a href="https://www.toronto.ca/services-payments/water-environment/managing-rain-melted-snow/basement-flooding/basement-flooding-protection-subsidy-program/">
                        {t("waterproof.backwatervalve.p2_c")}
                      </a>
                      {t("waterproof.backwatervalve.p2_d")}
                    </p>
                  </div>
                }
              />
              <Service
                id="sumppump"
                icon={
                  <img
                    className="serviceTitleIcon"
                    src={pumpIcon32}
                    alt={pumpIcon32}
                  />
                }
                title={t("waterproof.sumppump.title")}
                slides={[
                  require("./images/sumppump/slides/slide1.png"),
                  require("./images/sumppump/slides/slide2.png"),
                  require("./images/sumppump/slides/slide3.png"),
                  require("./images/sumppump/slides/slide4.png"),
                  require("./images/sumppump/slides/slide5.png"),
                  require("./images/sumppump/slides/slide6.png"),
                ]}
                popups={[
                  require("./images/sumppump/popups/slide1.png"),
                  require("./images/sumppump/popups/slide2.png"),
                  require("./images/sumppump/popups/slide3.png"),
                  require("./images/sumppump/popups/slide4.png"),
                  require("./images/sumppump/popups/slide5.png"),
                  require("./images/sumppump/popups/slide6.png"),
                ]}
                description={
                  <div>
                    <p>{t("waterproof.sumppump.p1")}</p>
                    <p>
                      {t("waterproof.sumppump.p2_a")}
                      <strong>$1,750</strong>
                      {t("waterproof.sumppump.p2_b")}
                      <a href="https://www.toronto.ca/services-payments/water-environment/managing-rain-melted-snow/basement-flooding/basement-flooding-protection-subsidy-program/">
                        {t("waterproof.sumppump.p2_c")}
                      </a>
                      {t("waterproof.sumppump.p2_d")}
                    </p>
                  </div>
                }
              />
            </div>
            <div className="col-md-5 col-lg-4">
              <div className="sticky-top">
                <React.Fragment>
                  <Quotation service={t("waterproof.title")} />
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(WaterProof);
