import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Slider from "./slider/slider";
import ServiceIcon from "../service/serviceicon";
import DrainsewerImage from "./images/drainsewer.png";
import PlumbingImage from "./images/plumbing.png";
import WaterImage from "./images/water.png";
import WaterProofImage from "./images/waterproofing.png";
import WaterDamageImage from "./images/waterdamage.png";
import PastJobsImage from "./images/pastjobs.png";
import About from "./about/about";
import "./main.css";

class Main extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <main id="main">
        <Slider />
        <div className="serviceIcons">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6 col-md-4 col-lg-2">
                <ServiceIcon
                  href="/plumbing"
                  img={
                    <img
                      src={PlumbingImage}
                      className="rounded-circle"
                      alt={t("plumbing.title")}
                    />
                  }
                  title={t("plumbing.title")}
                />
              </div>
              <div className="col-6 col-md-4 col-lg-2">
                <ServiceIcon
                  href="/drainsewer"
                  img={
                    <img
                      src={DrainsewerImage}
                      className="rounded-circle"
                      alt={t("drainsewer.title")}
                    />
                  }
                  title={t("drainsewer.title")}
                />
              </div>
              <div className="col-6 col-md-4 col-lg-2">
                <ServiceIcon
                  href="/water"
                  img={
                    <img
                      src={WaterImage}
                      className="rounded-circle"
                      alt={t("water.title")}
                    />
                  }
                  title={t("water.title")}
                />
              </div>
              <div className="col-6 col-md-4 col-lg-2">
                <ServiceIcon
                  href="/waterproof"
                  img={
                    <img
                      src={WaterProofImage}
                      className="rounded-circle"
                      alt={t("waterproof.title")}
                    />
                  }
                  title={t("waterproof.title")}
                />
              </div>
              <div className="col-6 col-md-4 col-lg-2">
                <ServiceIcon
                  href="/waterdamage"
                  img={
                    <img
                      src={WaterDamageImage}
                      className="rounded-circle"
                      alt={t("waterdamage.title")}
                    />
                  }
                  title={t("waterdamage.title")}
                />
              </div>
              <div className="col-6 col-md-4 col-lg-2">
                <ServiceIcon
                  href="/pastjobs"
                  img={
                    <img
                      src={PastJobsImage}
                      className="rounded-circle"
                      alt={t("main.pastjobstitle")}
                    />
                  }
                  title={t("main.pastjobstitle")}
                />
              </div>
            </div>
          </div>
        </div>
        <About />
      </main>
    );
  }
}

export default withTranslation()(Main);